<template>
  <v-container fluid>
    <div class="d-flex">
      <div class="flex-grow-1">
        <h3>상하 순서 이동</h3>
        <div class="list-box mt-3 pa-3 white">
          <div
            slot="header"
            class="btn-group list-group-item"
            role="group"
            aria-label="Basic example"
          >
            <v-btn class="btn btn-secondary white--text cyan" @click="add"
              >추가</v-btn
            >
            <v-btn
              class="btn btn-secondary ml-3 white--text cyan"
              @click="replace"
              >초기화</v-btn
            >
            <v-btn
              class="btn btn-secondary ml-3 white--text cyan"
              @click="list1_enabled = !list1_enabled"
              >{{ list1_btn_text }}</v-btn
            >
          </div>
          <draggable
            v-model="list1"
            :disabled="!list1_enabled"
            class="list-group"
            ghost-class="ghost"
            :move="checkMove"
            @start="list1_dragging = true"
            @end="list1_dragging = false"
          >
            <transition-group type="transition" name="flip-list1">
              <div
                class="pa-3 pink mt-3 hand list-item d-flex justify-center align-center white--text"
                v-for="element in list1"
                :key="element.name"
                v-bind:class="[
                  list1_enabled ? '' : 'disabled',
                  'list-box mt-2 pa-3 white',
                ]"
              >
                {{ element.name }}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div class="flex-grow-0">&nbsp;&nbsp;&nbsp;</div>
      <div class="flex-grow-1">
        <h3>좌우 순서 이동</h3>
        <div class="list-box mt-3 pa-3 white d-flex">
          <div class="flex-grow-1">
            <draggable
              class="list-group"
              :list="list2"
              group="people"
              @change="log"
            >
              <transition-group type="transition" name="flip-list">
                <div
                  class="pa-3 blue mt-3 hand list-group-item d-flex justify-center align-center white--text"
                  v-for="(element, index) in list2"
                  :key="element.name"
                >
                  {{ element.name }}
                </div>
              </transition-group>
            </draggable>
          </div>
          <div class="flex-grow-1 d-flex justify-center align-center">
            <v-icon large color="blue-grey"> mdi-swap-horizontal-bold </v-icon>
          </div>
          <div class="flex-grow-1">
            <draggable
              class="list-group"
              :list="list3"
              group="people"
              @change="log"
            >
              <transition-group type="transition" name="flip-list">
                <div
                  class="pa-3 red mt-3 hand list-group-item d-flex justify-center align-center white--text"
                  v-for="(element, index) in list3"
                  :key="element.name"
                >
                  {{ element.name }}
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
      <div class="flex-grow-0">&nbsp;&nbsp;&nbsp;</div>
      <div class="flex-grow-1">
        <h3>영역 이동</h3>
        <div class="list-box mt-3 pa-3 white d-flex">
          <div class="flex-grow-1">
            <!-- <p>{{ list4_selected }}</p> -->
            <template>
              <v-simple-table height="300" Dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="blue-grey lighten-4 flex-grow-0 text-center"
                        style="width: 40px"
                      >
                        <v-checkbox
                          v-model="list4_selected.length > 0"
                          hide-details
                          label=""
                          @click="allCheckbox('list4')"
                          v-ripple="{ class: 'red--text' }"
                          class="align-self-center"
                        ></v-checkbox>
                      </th>
                      <th
                        class="blue-grey lighten-4 flex-grow-1 text-center subtitle-2"
                      >
                        메뉴명
                      </th>
                      <th
                        class="blue-grey lighten-4 flex-grow-1 text-center subtitle-2"
                      >
                        메뉴ID
                      </th>
                    </tr>
                  </thead>

                  <draggable v-model="list4" group="dregdrop03" tag="tbody">
                    <tr v-for="item in list4" :key="item.name">
                      <td class="d-flex justify-center align-center">
                        <v-checkbox
                          v-model="list4_selected"
                          label=""
                          :value="item"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-center">{{ item.name }}</td>
                      <td class="text-center">{{ item.calories }}</td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </template>
          </div>
          <div
            class="flex-grow-1 d-flex flex-column justify-center align-center"
          >
            <div>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="grey"
                @click="itemMove('left')"
              >
                <v-icon dark> mdi-arrow-left-bold </v-icon>
              </v-btn>
            </div>
            <div class="mt-3">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="grey"
                @click="itemMove('right')"
              >
                <v-icon dark> mdi-arrow-right-bold </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="flex-grow-1">
            <!-- <v-data-table
              v-model="list5_selected"
              :headers="list4_headers"
              :items="list5"
              :single-select="false"
              show-select
              item-key="name"
              class="elevation-1"
              hide-default-footer
              height="200"
              Dense
              fixed-header
            >
            </v-data-table> -->

            <template>
              <!-- <p>{{ list5_selected }}</p> -->
              <v-simple-table height="300" Dense fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="blue-grey lighten-4 grow-0"
                        style="width: 40px"
                      >
                        <v-checkbox
                          v-model="list5_selected.length > 0"
                          label=""
                          @click="allCheckbox('list5')"
                          hide-details
                        ></v-checkbox>
                      </th>
                      <th
                        class="blue-grey lighten-4 flex-grow-1 text-center subtitle-2"
                      >
                        메뉴명
                      </th>
                      <th
                        class="blue-grey lighten-4 flex-grow-1 text-center subtitle-2"
                      >
                        메뉴ID
                      </th>
                      <!-- <th
                        v-for="(item, index) in list4_headers"
                        :key="'list5_header_' + item.value"
                      >
                        {{ item.text }}
                      </th> -->
                    </tr>
                  </thead>

                  <draggable
                    v-model="list5"
                    group="dregdrop03"
                    tag="tbody"
                    ghost-class="ghost03"
                  >
                    <tr v-for="item in list5" :key="item.name">
                      <!-- <td scope="row">{{ item.id }}</td> -->
                      <td class="d-flex justify-center align-center">
                        <v-checkbox
                          v-model="list5_selected"
                          label=""
                          :value="item"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-center">{{ item.name }}</td>
                      <td class="text-center">{{ item.calories }}</td>
                    </tr>
                  </draggable>
                </template>
              </v-simple-table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
let id = 3;
export default {
  name: "DragAndDrop",
  components: {
    draggable,
  },
  props: {},
  data() {
    return {
      list1: [
        { name: "홍길동", id: 0 },
        { name: "둘리", id: 1 },
        { name: "도우너", id: 2 },
      ],
      list1_enabled: true,
      list1_dragging: false,
      list1_btn_text: "비활성화",
      list2: [
        { name: "서울", id: 1 },
        { name: "경기", id: 2 },
        { name: "대전", id: 3 },
        { name: "인천", id: 4 },
      ],
      list3: [
        { name: "대구", id: 5 },
        { name: "부산", id: 6 },
        { name: "강원", id: 7 },
      ],
      list4: [
        {
          id: 1,
          name: "채팅상담",
          calories: "M01",
        },
        {
          id: 2,
          name: "전화상담",
          calories: "M02",
        },
        {
          id: 3,
          name: "모니터링",
          calories: "M03",
        },
        {
          id: 4,
          name: "대시보드",
          calories: "M04",
        },
        {
          id: 5,
          name: "상담이력관리",
          calories: "M05",
        },
        {
          id: 6,
          name: "설정관리",
          calories: "M06",
        },
      ],
      list5: [
        {
          id: 7,
          name: "지식관리",
          calories: "M07",
        },
        {
          id: 8,
          name: "SMS/LMS",
          calories: "M08",
        },
        {
          id: 9,
          name: "게시판",
          calories: "M09",
        },
        {
          id: 10,
          name: "통계",
          calories: "M10",
        },
      ],
      list4_headers: [
        {
          text: "메뉴명",
          value: "name",
          align: "center",
          sortable: true,
          class: "blue-grey lighten-4",
        },
        {
          text: "메뉴ID",
          value: "calories",
          align: "center",
          sortable: true,
          class: "blue-grey lighten-4",
        },
      ],
      list4_selected: [],
      list5_selected: [],
    };
  },
  computed: {
    draggingInfo() {
      return this.list1_dragging ? "under drag" : "";
    },
  },
  watch: {
    list1_enabled() {
      if (this.list1_enabled === false) {
        this.list1_btn_text = "활성화";
      } else {
        this.list1_btn_text = "비활성화";
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    add: function () {
      this.list1.push({ name: "신규" + id, id: id++ });
    },
    replace: function () {
      this.list1 = [];
    },
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    log: function (evt) {
      window.console.log(evt);
    },
    itemMove(type) {
      if (type === "left") {
        //배열추가
        this.list4 = [...this.list4, ...this.list5_selected];
        //배열삭제
        for (var i = 0; i < this.list5_selected.length; i++) {
          this.list5 = this.list5.filter(
            (element) => element != this.list5_selected[i]
          );
        }
        //선택값 초기화
        this.list5_selected = [];
      } else {
        //배열추가
        this.list5 = [...this.list5, ...this.list4_selected];
        //배열삭제
        for (var a = 0; a < this.list4_selected.length; a++) {
          this.list4 = this.list4.filter(
            (element) => element != this.list4_selected[a]
          );
        }
        //선택값 초기화
        this.list4_selected = [];
      }
    },
    allCheckbox(id) {
      // console.log("######## [allCheckbox] id=", this.,${id});
      console.log(
        "######## [allCheckbox] this.list5_selected.length=",
        this.list5_selected.length
      );

      if (id === "list4") {
        //하나도 체크되어 있지 않으면 전체 체크
        if (this.list4_selected.length === 0) {
          this.list4_selected = [];
          for (let i = 0; i < this.list4.length; i++) {
            this.list4_selected.push(this.list4[i]);
          }
        } else {
          //아니라면 전체 해제
          this.list4_selected = [];
        }
      } else if (id === "list5") {
        //하나도 체크되어 있지 않으면 전체 체크
        if (this.list5_selected.length === 0) {
          this.list5_selected = [];
          for (let i = 0; i < this.list5.length; i++) {
            this.list5_selected.push(this.list5[i]);
          }
        } else {
          //아니라면 전체 해제
          this.list5_selected = [];
        }
      }
    },
  },
};
</script>

<style scoped>
.list-box {
  flex-basis: 50%;
}

.disabled {
  opacity: 0.6;
}

.ghost {
  opacity: 0.5;
  box-shadow: 0 0 0 3px orange inset;
  background: red !important;
}

.ghost03 {
  opacity: 0.5;
  box-shadow: 0 0 0 3px orange inset;
  /* background: red !important; */
}

.hand {
  cursor: pointer;
}

.hand.disabled {
  cursor: not-allowed;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

#dragTable01 table tr th {
  background: red !important;
}
</style>
